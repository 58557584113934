<template>
<div>
    <div class="wrap">
        <form name="mfrm" action="/menu_management_status/index" method="post">
            <div class="con">
                <div class="con_table">
                    <div class="con_top mb-10">
                        <h2><span>■</span> {{name}} 상세보기</h2>
                    </div>
                    <div class="btns mb-30">
                        <a class="btn_new2 pointer" @click="register()">수납등록</a>
                    </div>
                    <div class="search2 left">
                        <input type="hidden" name="_token" id="_token" value="EqLJL0tVa23F5ikcDmzYVhVNw50lB9k5eDl840Bi">
                        <input type="hidden" name="this_month" id="this_month" value="2020-10">
                        <div>기간 검색 :
                            <crm-datepicker v-model="startDate" :parentDate="startDate" format="yyyy-MM-dd" :language="$store.state.ko"></crm-datepicker> ~
                            <crm-datepicker v-model="endDate" :parentDate="endDate" class="mr-10" format="yyyy-MM-dd" :language="$store.state.ko"></crm-datepicker>
                            <a class="btn_search pointer" @click="getPurchase(idxCrmCompany)">Search<span></span></a>
                        </div>
                    </div>
                    <br>
                    <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00">
                        <tbody>
                            <tr>
                                <th>총 수납액</th>
                                <td class="left pl-20">
                                    {{numberWithCommas(purchaseSum.payPrice - purchaseSum.refundPrice)}} 원
                                </td>
                                <th>총 미수금</th>
                                <td class="left pl-20">
                                    {{numberWithCommas(purchaseSum.noPrice)}} 원
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br>
                    <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00 mt-20">
                        <tbody>
                            <tr>
                                <th class="w-120px">수납일자</th>
                                <th class="w-120px">구분</th>
                                <th class="w-120px">금액</th>
                                <th class="w-120px">특이사항</th>
                                <th class="w-120px">관리</th>
                            </tr>
                            <tr v-for="(item, index) of purchase" :key="index">
                                <td>{{item.purDate.slice(0,10)}}</td>
                                <td>{{item.status}}</td>
                                <td>{{numberWithCommas(item.purPrice)}} 원</td>
                                <td>{{item.memo}}</td>
                                <td><span class="underline pointer" @click="modify(item.idx)">수정</span><span> | </span><span class="underline pointer" @click="remove(item.idx)">삭제</span></td>
                            </tr>
                            <tr v-if="purchase.length === 0">
                                <td colspan="6">수납 정보가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="con_table">
                    <div class="page">
                        <ul>
                            <li>
                                <a class="pointer" @click="prevPaging()"> &lt; </a>
                            </li>
                            <li v-for="index in calcMaxPaging()" :key="index" class="txt-blue">
                                <span class="pointer" style="padding: 8px;" @click="paging(index)">
                                    <a :class="{'bold': index === pageNum}">{{index}}</a>
                                </span>
                            </li>
                            <li>
                                <a class="pointer" @click="nextPaging()"> &gt; </a>
                            </li>
                        </ul>
                    </div>
                    <div class="btns3 mgB80">
                        <a class="btn_sms mr-10 pointer" @click="prev()">목록</a>
                        <!-- <a class="btn_cancle mr-10 pointer" @click="remove()">삭제</a>
                        <a class="btn_cancle pointer" @click="modify()">수정</a> -->
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
</template>

<script>
import {
    GetUrlParams
} from '@/utils/common.js'
export default {
    data: () => ({
        idx: -1,
        idxCrmClient: -1,
        idxCrmCompany: -1,
        name: '',
        // 상담일지목록
        purchase: [],
        purchaseSum: [],
        startDate: '', // 기간검색-시작날짜
        endDate: '', // 기간검색-종료날짜

        // 페이징에 필요한 변수들
        pageNum: 1,
        pageSize: 50,
        listLength: 0, // 리스트 size
        maxPagingCount: 1, // 최대 페이지 수
        maxPagePart: 1, // 최대 파트가 몇번째인지
        pagePart: 1, // 현재 페이징이 몇번째 파트에 있는지 (ex 1~10 1파트, 11~20 2파트)
    }),

    mounted() {
        /* this.startDate = this.formatDate(new Date())
        this.endDate = this.formatDate(new Date()) */
        var oParams = GetUrlParams()
        this.idxCrmCompany = oParams.idxCrmCompany

        if(sessionStorage.getItem('origin_acceptanceviewb2b_pageNum') !== 'undefined' && sessionStorage.getItem('origin_acceptanceviewb2b_pageNum') !== null) {
            this.pageNum = Number(sessionStorage.getItem('origin_acceptanceviewb2b_pageNum'))
        }
        if(sessionStorage.getItem('origin_acceptanceviewb2b_startDate') !== 'undefined' && sessionStorage.getItem('origin_acceptanceviewb2b_startDate') !== null) {
            this.startDate = sessionStorage.getItem('origin_acceptanceviewb2b_startDate')
        }
        if(sessionStorage.getItem('origin_acceptanceviewb2b_endDate') !== 'undefined' && sessionStorage.getItem('origin_acceptanceviewb2b_endDate') !== null) {
            this.endDate = sessionStorage.getItem('origin_acceptanceviewb2b_endDate')
        }

        this.getPurchase(this.idxCrmCompany)
    },

    methods: {

        // 오늘 날짜 YYYY-MM-DD로 지정하는 함수
        formatDate(date) {
            if (date) {
                var d = new Date(date),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();
                if (month.length < 2) month = '0' + month;
                if (day.length < 2) day = '0' + day;
                return [year, month, day].join('-');
            } else if (date === '') {
                return ''
            } else {
                var d = new Date(),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();
                if (month.length < 2) month = '0' + month;
                if (day.length < 2) day = '0' + day;
                return [year, month, day].join('-');
            }
        },

        // 수납수정 페이지로 이동
        modify (idx) {
            this.$router.push(`/origin/customer_acceptance_modify_b2b?idx=${idx}`)
        },

        // 수납등록 페이지로 이동
        register () {
            this.$router.push(`/origin/customer_acceptance_register_b2b?idxCrmCompany=${this.idxCrmCompany}`)
        },
        
        // 이전 페이징
        prevPaging() {
            if (this.pageNum === 1) {
                return false
            } else {
                this.pageNum -= 1
                this.getPurchase(this.idxCrmCompany)
            }
        },
        paging(index) {
            this.pageNum = index
            this.getPurchase(this.idxCrmCompany)
        },

        // 현재 페이지의 페이지 범위 계산
        calcMaxPaging() {
            if (this.maxPagingCount > this.pagePart * 10) {
                this.maxPagePart = this.pagePart * 10
            } else {
                this.maxPagePart = this.maxPagingCount
            }
            var pagingArray = []
            for (var i = ((this.pagePart - 1) * 10) + 1; i <= this.maxPagePart; i++) {
                pagingArray.push(i)
            }
            return pagingArray
        },

        // 다음 페이징
        nextPaging() {
            if (this.pageNum === this.maxPagingCount) {
                return false
            } else {
                this.pageNum += 1
                this.getPurchase(this.idxCrmCompany)
            }
        },

        // 컴마 넣기
        numberWithCommas(x) {
            if(x === undefined || isNaN(x)) {
                return 0
            } else {
                x = x + ''
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        },

        getPurchase(idxCrmCompany) {

            let idx = idxCrmCompany
            let params = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                startDate: this.startDate,
                endDate: this.endDate
            }

            sessionStorage.setItem('origin_acceptanceviewb2b_pageNum', this.pageNum)
            sessionStorage.setItem('origin_acceptanceviewb2b_startDate', this.formatDate(this.startDate))
            sessionStorage.setItem('origin_acceptanceviewb2b_endDate', this.formatDate(this.endDate))

            this.axios.get(`/api/v1/client/pur/b2b/${idx}`, {
                params: params
            })
                .then(res => {
                    if (res.data.err === 0) {
                        this.purchase = res.data.purchase
                        this.purchaseSum = res.data.purchaseSum
                        this.name = res.data.purchase[0].name
                        console.log('VVS', this.purchaseSum)
                        if (res.data.purchaseSize) {
                            this.listLength = res.data.purchaseSize
                        }
                        this.maxPagingCount = Math.ceil(this.listLength / this.pageSize)
                        this.maxPagePart = Math.ceil(this.maxPagingCount / 10)
                        this.pagePart = Math.ceil(this.pageNum / 10)
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if(err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        // B2C 수납정보 목록페이지로
        prev() {
            this.$router.push('/origin/customer_acceptance_b2b')
        },

        // 수납정보 삭제
        remove(idx) {
            var con_test = confirm("삭제하면 복구가 불가능합니다.\n정말 삭제하시겠습니까?");
            if (con_test == true) {
                this.axios.delete(`/api/v1/client/pur/${idx}`, {})
                    .then(res => {
                        if (res.data.err === 0) {
                            alert(res.data.result)
                            this.$router.go(this.$router.currentRoute)
                        } else {
                            alert('삭제할 수 없습니다.')
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else {
                return false
            }
        }

    }
}
</script>
